import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: { seoMetaTags, title, bannerText, modularBlocks },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner heading={title} text={bannerText} />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerText
      modularBlocks {
        ...AccordionModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentFeaturedLinksModularBlockFragment
        ...ContentBlocksModularBlockFragment
        ...ContentModularBlockFragment
        ...ContentCtaFeaturedLinksModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedProjectsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HighlightedLogosModularBlockFragment
        ...ImageContentCtaModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageSliderModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...LatestNewsModularBlockFragment
        ...LogosStripModularBlockFragment
        ...StatementTextModularBlockFragment
        ...TabsModularBlockFragment
        ...TeamProfilesModularBlockFragment
        ...TestimonialModularBlockFragment
        ...TextCtaModularBlockFragment
      }
    }
  }
`;

export default DefaultPageTemplate;
